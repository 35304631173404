<template>
  <b-card>
    <b-card-header><b class="h1">Basic information</b></b-card-header>
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="*Avatar:" label-cols-md="3">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style="max-width: 200px;"
                >
                  <b-aspect aspect="1:1">
                    <b-img-lazy
                      :src="model.avatar"
                      fluid
                      thumbnail
                      rounded
                      style="position: absolute; max-width: 200px;"
                    />
                    <div
                      class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                    >
                      <b-form-file
                        accept=".jpg, .png, .gif"
                        class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                        @change="previewFiles"
                      />
                      <b-button
                        variant="outline-primary"
                        class="btn-icon rounded-circle btn-add"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </div>
                  </b-aspect>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <b-form-group label="*Title:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                        @input="generateSlug(model.name)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Path:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Path"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.path"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Path"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Api:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Api"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.api_id"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Api"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Component category:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Component category"
                      rules="required"
                    >
                      <v-select
                        v-if="categoryOptions"
                        v-model="model.component_category_id"
                        label="name"
                        :reduce="x => x.id"
                        :options="categoryOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Component Group:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Component Group"
                      rules="required"
                    >
                      <v-select
                        v-if="groupOptions"
                        v-model="model.component_group_id"
                        label="name"
                        :reduce="x => x.id"
                        :options="groupOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Position:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Position"
                      rules="required"
                    >
                      <b-form-input
                        v-model="model.position"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Position"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Is active:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Is active"
                      rules="required"
                    >
                      <v-select
                        v-model="model.is_active"
                        label="text"
                        :reduce="x => x.value"
                        :options="activeOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="*Type:" label-cols-md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-select
                        v-model="model.type"
                        label="text"
                        :reduce="x => x.value"
                        :options="typeOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Param api:" label-cols-md="3">
                    <b-form-input
                      v-model="model.param_api"
                      placeholder="Param api"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Setting:" label-cols-md="3">
                    <b-form-textarea
                      v-model="model.setting"
                      placeholder="Setting"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col md="12">
              <b-form-group label="Short content:" label-cols-md="3">
                <b-form-textarea
                  v-model="model.short_content"
                  placeholder="Short content"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="*Content:" label-cols-md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Content"
                  rules="required"
                >
                  <quill-editor
                    id="content"
                    v-model="model.content"
                    :options="snowOption"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <!-- Submit -->
            <b-col md="12" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormFile,
  BImgLazy,
  BAspect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
// import { quillEditor } from 'vue-quill-editor'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardHeader,
    BCardBody,
    BForm,
    BFormTextarea,
    BFormFile,
    BImgLazy,
    BAspect,
    // quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: null,
        title: null,
        position: null,
        is_active: 1,
        slug: null,
        meta_description: null,
        meta_keyword: null,
        content: null,
        short_content: '',
        platform_blog_category_id: null,
      },
      snowOption: {
        theme: 'snow',
      },
      activeOptions: Config.activeOptions,
      categoryOptions: null,
      groupOptions: null,
      typeOptions: [
        { value: 0, text: 'Default' },
        { value: 1, text: 'Global' },
        { value: 2, text: 'Only' },
        { value: 3, text: 'Header' },
        { value: 4, text: 'Footer' },
      ],
    }
  },
  created() {
    this.loadCategoryList()
    this.loadGroupList()
  },
  methods: {
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        path: this.model.path,
        position: Number(this.model.position),
        is_active: Number(this.model.is_active),
        type: Number(this.model.type),
        api_id: this.model.api_id,
        setting: this.model.setting,
        param_api: this.model.param_api ? this.model.param_api : '',
        // short_content: this.model.short_content,
        // content: this.model.content,
        component_category_id:
          this.model.component_category_id,
        component_group_id: this.model.component_group_id,
      }
      if (this.model.avatar !== null) {
        params.avatar = this.model.avatar
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/component`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Blog success',
                  },
                })
                this.$router.push('/component/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadCategoryList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/component_categories?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categoryOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadGroupList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/component_groups?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.groupOptions = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
